 /deep/ .uploadFile .upload-btn {
  margin-top: 6px !important;
}
 /deep/ .uploadFile .upload-btn .el-upload {
  height: auto;
  border: none;
}
.applyAndSeeData .el-form-item {
  margin-bottom: 10px;
}
.applyAndSeeData .uploadFile {
  margin-top: 20px;
}
.applyAndSeeData .uploadFile .applyAnnexList {
  display: flex;
  height: 30px;
  line-height: 30px;
}
.applyAndSeeData .uploadFile .applyAnnexList .applyAnnexList_1 {
  width: 400px;
  line-height: 30px;
}
.applyAndSeeData .uploadFile .applyAnnexList .applyAnnexList_2 {
  color: #409eff;
  margin: 0 10px;
  cursor: pointer;
}
.applyAndSeeData .uploadFile .applyAnnexList .applyAnnexList_3 {
  color: #F56C6C;
  margin: 0 10px;
  cursor: pointer;
}
