
































































































































































































































































































































































/deep/ .uploadFile .upload-btn {
  margin-top: 6px !important;
}

/deep/ .uploadFile .upload-btn .el-upload {
  height: auto;
  border: none;
}

.applyAndSeeData {
  .el-form-item {
    margin-bottom: 10px;
  }

  .uploadFile {
    margin-top: 20px;

    .applyAnnexList {
      display: flex;
      height: 30px;
      line-height: 30px;

      .applyAnnexList_1 {
        width: 400px;
        line-height: 30px;
      }

      .applyAnnexList_2 {
        color: #409eff;
        margin: 0 10px;
        cursor: pointer;
      }

      .applyAnnexList_3 {
        color: #F56C6C;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
}
